import { createRouter, createWebHistory } from '@ionic/vue-router';
// import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import CaseOne from '../views/1.vue'
import CaseTwo from '../views/2.vue'
import CaseThree from '../views/3.vue'
import CaseFour from '../views/4.vue'
import CaseFive from '../views/5.vue'
import CaseSix from '../views/6.vue'
import CaseSeven from '../views/7.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/1',
    component: CaseOne
  },
  {
    path: '/2',
    component: CaseTwo
  },
  {
    path: '/3',
    component: CaseThree
  },
  {
    path: '/4',
    component: CaseFour
  },
  {
    path: '/5',
    component: CaseFive
  },
  {
    path: '/6',
    component: CaseSix
  },
  {
    path: '/7',
    component: CaseSeven
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
