<template>
  <ion-page>
    <ion-content :fullscreen="false">
      <div id="container">
        <ion-grid class="animate">
          <ion-row>
            <ion-col size="1"> </ion-col>
            <ion-col size="10">
              <img
                @click="animate"
                src="assets/nwad_logo.png"
              />
            </ion-col>
            <ion-col size="1"> </ion-col>
          </ion-row>
        </ion-grid>
      <ion-grid id="button-grid" class="opacity-0">
        <ion-row>
          <ion-col class="ion-text-center"><h1>North West Airway Database</h1></ion-col>
        </ion-row>
        <ion-row class="ion-align-items-baseline">
          <ion-col class="ion-text-center"><ion-button @click="openModal">Info</ion-button></ion-col>
          <ion-col class="ion-text-center"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdWn1HmniMs3UkgNiVanxcYP2mrCOvO9paIBfZ1PW9ol1K5iw/viewform?embedded=true"><ion-button>Submit</ion-button></a></ion-col>
          <ion-col class="ion-text-center"><ion-button href="1">Learn</ion-button></ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="opacity-next">
        <ion-row>
          <ion-col size="8" offset="2">
            <p><em>‘The only source of knowledge is experience’</em><br />
            &mdash;  Albert Einstein<br /><br />
            </p>
            <p>The Northwest Airway Database will allow participants to gain knowledge through cases shared by others.</p>
          </ion-col>
        </ion-row>
      </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  modalController,
} from "@ionic/vue";
import Modal from '../components/Modal.vue';
import { defineComponent } from "vue";
import anime from "animejs";

export default defineComponent({
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  methods: {
    async openModal() {
      const modal = await modalController
        .create({
          component: Modal,
          cssClass: 'my-custom-class',
          componentProps: {
            title: 'About NWAD',
            content: 'info'
          },
        })
      return modal.present();
    },
    async close() {
      await modalController.dismiss();
    },
    animate() {
      anime({
        targets: ".animate",
        keyframes: [
          { opacity: [0, 1], duration: 1000 },
          { translateY: "-10%", scale: 0.7, duration: 1000 },
        ],
        easing: "easeInOutSine",
        complete: function() {
          anime({
            targets: ".opacity-0",
            opacity: [0, 1],
            duration: 500,
            complete: function() {
              anime({
                targets: ".opacity-next",
                opacity: [0, 1],
                duration: 500,
              })
            }
          });
        }
      });
    },
  },
  mounted() {
    this.animate();
  },
});
</script>

<style scoped>
body {
  background: #efefef;
}
.animate {
  opacity: 0;
}
.opacity-0 {
  opacity: 0;
  position: relative;
  margin-top: -5%;
}
.opacity-next {
  opacity: 0
}
#container {
  text-align: center;


}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
