<template>
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" auto-hide="false">
      <ion-button href="/1">
        <ion-icon :icon="arrowBack" />
      </ion-button>
    </ion-buttons>
    <ion-title>Case 2</ion-title>
    <ion-buttons slot="end">
      <ion-button href="/3">
        <ion-icon :icon="arrowForward" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-grid><ion-row><ion-col>

<h3>Background</h3>

<p>41 year old lady presented at the ED at 0430h following a fall through a plate glass door. Patient had received multiple lacerations to right forearm and a large laceration to the neck, completely transecting the pharyngeal inlet above the level of the thyroid cartilage. Vocal chords were visible through the wound. No major vessels were cut. The patient was able to phonate with her head tilted forward. She could not swallow. There was no stridor. Patient was cardiovascularly stable, awake, alert and saturating normally. She was understandably very anxious.

</p>

<p>ENT consultant was called and patient was transferred to theatre via CT scan. CT scan showed no significant airway swelling and no deep vascular injury. Anaesthetic consultant was not contacted.</p>

<p>Patient still in theatre waiting area when daytime emergency consultant anaesthetist arrived at 0745h. Following discussion with ENT consultant, patient transferred to theatre for repair of Pharyngeal laceration.

</p>

<h3>Strategy</h3>

<p>General:</p>
<ul>
<li>RSI without cricoid pressure (due to patient discomfort)</li>
<li>ENT surgeon scrubbed</li>
</ul>

<p>Airway:</p>
<ol>
<li>Attempt oral intubation with reinforced size 7.0 ET tube with aid of Glidescope</li>
<li>Intubate directly through the wound with reinforced size 6.0 ET tube with the aid of a bougie.</li>
<li>Surgical tracheostomy</li>
</ol>

<h3>Event</h3>
<p>Adequate pre-oxygenation difficult due to deficit in neck. Induced with propofol 160mg and suxamethonium 100mg. Laryngoscopy gave good view of chords but tube flipped out through wound as larynx mobile. Patient started to desaturate and therefore was intubated directly through wound with a reinforced tube aided by a bougie. Tube exchange then facilitated by the surgeon with the aid of direct rigid laryngoscope.</p>

<p>Laryngeal wound repaired. Patient kept intubated for repair of forearm tendons and transferred to ICU because of risk of laryngeal oedema. Direct laryngoscopy at 24h showed no oedema. Pt. extubated and returned to ward.</p>

<h3>Learning points</h3>
<ul><li>Call anaesthetic consultant early.</li>
<li>Ask ENT surgeon to do formal tracheostomy at time of surgery in order to avoid the complications of long term intubation on ICU. Laryngeal oedema would then not be an issue</li>
</ul>

</ion-col>
</ion-row>
</ion-grid>
</ion-content>
</template>
<script>
import {
  IonContent,
  IonGrid,
  IonIcon,
  IonButton,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonRow,
  IonCol,
  IonButtons,
} from "@ionic/vue";
import { arrowForward, arrowBack } from 'ionicons/icons';
import { defineComponent } from "vue";

export default defineComponent({
  name: "Case1",
  inheritAttrs: false,
  components: {
    IonContent,
    IonGrid,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonHeader,
    IonRow,
    IonCol,
    IonButtons,
  },
  setup() {
    return {
      arrowForward, arrowBack
    }
  }
});
</script>
<style>
ion-page {
	margin-top: 50px;
}
</style>