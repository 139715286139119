<template>
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" auto-hide="false">
      <ion-button href="/6">
        <ion-icon :icon="arrowBack" />
      </ion-button>
    </ion-buttons>
    <ion-title>Case 7</ion-title>
<!--    <ion-buttons slot="end">
      <ion-button href="/7">
        <ion-icon :icon="arrowForward" />
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>
<ion-content>
<ion-grid><ion-row><ion-col>

CASE 7

</ion-col>
</ion-row>
</ion-grid>
</ion-content>
</template>
<script>
import {
  IonContent,
  IonGrid,
  IonIcon,
  IonButton,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonRow,
  IonCol,
  IonButtons,
} from "@ionic/vue";
import { arrowForward, arrowBack } from 'ionicons/icons';
import { defineComponent } from "vue";

export default defineComponent({
  name: "Case1",
  inheritAttrs: false,
  components: {
    IonContent,
    IonGrid,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonHeader,
    IonRow,
    IonCol,
    IonButtons,
  },
  setup() {
    return {
      arrowForward, arrowBack
    }
  }
});
</script>
<style>
ion-page {
	margin-top: 50px;
}
</style>