<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div v-if="content === 'info'">
      <p align="center">
      "‘Stories’ (cases) hold further power as they make people realise the human impact of adverse events and thus provide a motive to seek to gain further expertise in the field"<br /><br />
<em>The Health Foundation &mdash; Inspiring Improvement</em>
</p>
<br /><br />
Northwest airway database (NWAD) is a regional reporting system that receives complex airway cases from the relevant participants and shares their experiences with those that have access to the database. It allows participants to gain and reflect on experiences of others and to share their own experiences.
<br /><br />
Overall, the database allows one to learn and improve airway management, in particular, with complex and demanding cases, resulting in improved patient safety. A successful outcome would be to reduce airway related adverse events to zero.
<br /><br />
To submit a case, please click the submit icon on the main page and you will be directed to fill out a form about your experience.  The NW airway facilitators will finalise the case and it will be uploaded anonymously for all participants to view and engage with. 
<br /><br />
Guidance for reporters:
<ul>
<li>Easy to report</li>
<li>Anonymous</li>
<li>Narrative account</li>
<li>Describe the case chronologically, in a sequential manner, all information you believe to be relevant to the case</li>
<li>Include salient features of airway evaluation from the history, systemic enquiry, examinations or investigations</li>
<li>Include factors relevant to all parts of the airway management strategy, i.e. difficulties with bag-mask ventilation, supraglottic device placement, direct laryngoscopy or ‘front of neck’ techniques</li>
</ul>

Any issues please email nwamdatabase@gmail.com 
<br /><br />
Each case submitted will receive a certificate for internal CPD points accredited by the RCOA.

    </div>
  </ion-content>
  <ion-button @click="close">Close</ion-button>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    content: {type: String, default: 'Content'},
  },
  methods: {
    close() {
      modalController.dismiss();
    }
  },
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar }
});
</script>